<template>
    <form name="form-speaker" id="form-speaker" @submit="onSubmit">
        <input type="hidden" name="id" v-model="model.id">
        <input type="hidden" name="pic" v-model="model.pic">

        <div class="form-row">

            <div class="col-md-6">
                <div class="form-group">
                    <label for="name">Name</label>
                    <InputText class="form-control" v-model="model.name" id="name"></InputText>
                </div>

                <div class="form-group">
                    <label for="companyName">Company Name</label>
                    <InputText class="form-control" v-model="model.companyName" id="companyName"></InputText>
                </div>

                <div class="form-group">
                    <label for="currentPosition">Current Position</label>
                    <InputText class="form-control" v-model="model.currentPosition" id="currentPosition"></InputText>
                </div>

                <div class="form-group">
                    <label for="pic">PIC</label>
                    <FileUpload mode="advanced" name="file" :multiple="false" accept="image/*"  v-model="model.uploadFile" :url="uploadUrl" @error="onUploadError" @upload="onUploadSuccess"></FileUpload>
                </div>

                <div class="form-group">
                    <label for="priority">Priority</label>
                    <InputText class="form-control" v-model="model.priority" id="priority"></InputText>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="email">Email</label>
                    <InputText type="email" class="form-control" v-model="model.email" id="email"></InputText>
                </div>

                <div class="form-group">
                    <label for="phoneNumber">Phone Number</label>
                    <InputText class="form-control" v-model="model.phoneNumber" id="phoneNumber"></InputText>
                </div>

                <div class="form-group">
                    <label for="biography">Biography</label>
                    <Textarea class="form-control" v-model="model.biography" id="biography" rows="9"></Textarea>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-end mb-sm-1">
            <button type="button" class="btn btn-outline-secondary mr-sm-1" @click="onCancel">Cancel</button>
            <button type="submit" class="btn btn-primary" :disabled="saving">Submit</button>
        </div>

    </form>
</template>

<script>
import {defineComponent} from 'vue'
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import apiService from "@/services/ApiService";

export default defineComponent({
    name: "SpeakerForm",
    components: {
        InputText, Textarea, FileUpload
    },
    props: {
        id: {
            type: String,
            required: false
        },
        isNew: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            model: {
                id: '',
                name: '',
                email: '',
                phoneNumber: '',
                companyName: '',
                currentPosition: '',
                biography: '',
                pic: '',
                uploadFile: '',
                priority: '',
                image: ''
            },
            saving: false,
            uploadUrl: ''
        }
    },

    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.saving = true;

            apiService.post(`/speaker/edit`, this.model)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Speaker has been updated',
                        life: 5000
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Failed to update speaker',
                        detail: error.response.data.Message,
                        life: 5000
                    });
                })
                .finally(() => {
                    console.log('final');
                    this.saving = false;
                });
        },
        onCancel() {
            this.$router.push('/speaker')
        },
        onUploadSuccess(event){
            let response = JSON.parse(event.xhr.response);
            console.log(event.xhr.response);

            this.model.image = response.fileName;
            this.model.pic = response.fileName;

            this.$toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'File uploaded',
                life: 5000
            });
        },
        onUploadError(event){
            let response = JSON.parse(event.xhr.response);
            this.$toast.add({
                severity: 'error',
                summary: 'Failed to upload file',
                detail: response.Message,
                life: 5000
            });
        },
        getSpeakerById(id) {
            apiService.get(`/speaker/${id}`)
                .then(response => {
                    this.model = response.data;
                    this.model.image = this.model.pic;
                    this.model.uploadFile = ''

                }).catch(error => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Failed to get speaker',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        }
    },
    mounted() {
        this.uploadUrl = `${apiService.defaults.baseURL}/file/upload`;
        if(!this.isNew){
            this.getSpeakerById(this.id);
        }
    }
})
</script>


<style scoped>

</style>
