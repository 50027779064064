<template>
    <form name="form-speaker" id="form-speaker" @submit="onSubmit">
        <input type="hidden" name="id" v-model="model.id">

        <div class="form-row">

            <div class="col-md-6">
                <div class="form-group">
                    <label for="name">Member Name</label>
                    <InputText class="form-control" v-model="model.name" id="name"></InputText>
                </div>

                <div class="form-group">
                    <label for="status">Gender</label>
                    <select class="form-control" name="gender" id="gender" v-model="model.gender">
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="preferredBadgeName">Preferred Badge Name</label>
                    <InputText class="form-control" v-model="model.preferredBadgeName" id="preferredBadgeName"></InputText>
                </div>

                <div class="form-group">
                    <label for="designation">Designation</label>
                    <InputText class="form-control" v-model="model.designation" id="designation"></InputText>
                </div>

                <div class="form-group">
                    <label for="organizationName">Organization Name</label>
                    <InputText class="form-control" v-model="model.organizationName" id="organizationName"></InputText>
                </div>

                <div class="form-group">
                    <label for="address">Address</label>
                    <Textarea class="form-control" v-model="model.address" id="address" rows="5"></Textarea>
                </div>

            </div>

            <div class="col-md-6">

                <div class="form-group">
                    <label for="countryName">Country Name</label>
                    <InputText class="form-control" v-model="model.countryName" id="countryName"></InputText>
                </div>

                <div class="form-group">
                    <label for="cityName">City Name</label>
                    <InputText class="form-control" v-model="model.cityName" id="cityName"></InputText>
                </div>

                <div class="form-group">
                    <label for="phoneNumber">Phone Number</label>
                    <InputText class="form-control" v-model="model.phoneNumber" id="phoneNumber"></InputText>
                </div>

                <div class="form-group">
                    <label for="fax">Fax</label>
                    <InputText class="form-control" v-model="model.fax" id="fax"></InputText>
                </div>

                <div class="form-group">
                    <label for="website">Website</label>
                    <InputText class="form-control" v-model="model.website" id="website"></InputText>
                </div>

                <div class="form-group">
                    <label for="foodTypeName">Food Type</label>
                    <InputText class="form-control" v-model="model.foodTypeName" id="foodTypeName"></InputText>
                </div>

                <div class="form-group">
                    <label for="shirtSize">Polo Shirt Size</label>
                    <InputText class="form-control" v-model="model.shirtSize" id="shirtSize"></InputText>
                </div>

                <div class="form-group">
                    <label for="paymentMethodName">Payment Method</label>
                    <InputText class="form-control" v-model="model.paymentMethodName" id="paymentMethodName"></InputText>
                </div>

            </div>

        </div>

        <div class="d-flex justify-content-end mb-sm-1">
            <button type="button" class="btn btn-outline-secondary mr-sm-1" @click="onCancel">Cancel</button>
            <button type="submit" class="btn btn-primary" :disabled="saving">Submit</button>
        </div>

    </form>
</template>

<script>
import {defineComponent} from 'vue'
import apiService from "@/services/ApiService";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";

export default defineComponent({
    name: "MemberForm",
    props: {
        id: {
            type: String,
            required: false
        }
    },
    components: {
      InputText, Textarea
    },
    data() {
        return {
            model: {
                id: '',
                name: '',
                gender: '',
                preferredBadgeName: '',
                designation: '',
                organizationName: '',
                address: '',
                cityName: '',
                countryName: '',
                email: '',
                phoneNumber: '',
                fax: '',
                website: '',
                otherSpices: '',
                foodTypeName: '',
                shirtSize: '',
                paymentMethodName: ''
            },
            saving: false
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.saving = true;

            apiService.post(`/member/edit`, this.model)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Member has been updated',
                        life: 5000
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Failed to update member',
                        detail: error.response.data.Message,
                        life: 5000
                    });
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        onCancel() {
            this.$router.push('/member')
        },
        getMemberById(id) {
            apiService.get(`/member/${id}`)
                .then(response => {
                    console.log('getMemberById');
                    console.log(response);
                    this.model = response.data;
                }).catch(error => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Failed to get member',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        }
    },
    mounted() {
        this.getMemberById(this.id);
    }
})
</script>

<style scoped>

</style>
