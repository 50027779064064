<template>

    <div class="datatable-toolbar row mb-3">
        <div class="col col-4">
            <input type="text" class="form-control" placeholder="Search data" v-model="dataFilter.searchText" @keyup.enter="doFind();">
        </div>

        <div class="col col-1">
            <button class="btn btn-outline-primary" type="button" @click="doFind();">Search</button>
        </div>

        <div class="col col-2">
            <RouterLink to="/event/add" class="btn btn-primary">Add New</RouterLink>
        </div>

        <ConfirmDialog></ConfirmDialog>

    </div>

    <div class="row">
        <table class="table table-striped">

            <thead>
            <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Location</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in dataList" :key="data">
                <td class="order-number">{{ index + 1 }}</td>
                <td class="text-left">{{ data.name }}</td>
                <td>{{ data.startDate }}</td>
                <td>{{ data.endDate }}</td>
                <td class="text-left">{{ data.locationName }}</td>
                <td>
                    <span class="badge badge-dark" v-if="data.status !== 1"> {{ data.statusLabel }}</span>
                    <span class="badge badge-success" v-if="data.status === 1"> {{ data.statusLabel }}</span>
                </td>
                <td class="actions">
                    <button type="button" class="btn btn-outline-secondary" @click="onEdit(data)">Edit</button>
                    <button type="button" class="btn btn-outline-secondary" @click="onRemove(data)">Remove</button>
                    <button type="button" class="btn btn-outline-success" @click="onActivate(data)" v-if="data.status !== 1">Activate</button>
                </td>
            </tr>
            </tbody>

        </table>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import apiService from "@/services/ApiService";

export default defineComponent({
    name: "EventList",
    data() {
        return {
            dataList: [{
                id: '',
                name: '',
                startDate: '',
                endDate: '',
                description: '',
                locationName: '',
                statusLabel: '',
                status: '',
                latitude: '',
                longitude: '',
                locationDescription: ''
            }],
            showRemoveConfirmDialog: false,
            dataFilter: {
                searchText: ''
            }
        }
    },
    methods: {
        onEdit(data) {
            this.$router.push(`/event/edit/${data.id}`);
        },
        onRemove(data) {
            this.$confirm.require({
                message: 'Are you sure you want to remove ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.doRemove(data.id);
                }
            });
        },
        onActivate(data) {
            console.log(data);
        },
        doFind() {
            const params = {
                SearchText: this.dataFilter.searchText
            };
            apiService.get('/event', {params: params})
                .then(response => {
                    this.dataList = response.data.data;
                }).catch(error => {
                console.log(error);

                this.$toast.add({
                    severity: 'error',
                    summary: 'Failed to get event',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        },
        doRemove(id) {
            apiService.delete(`/event/${id}`)
                .then(() => {
                    this.doFind();

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Event has been removed',
                        life: 5000
                    });


                }).catch(error => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Failed to remove event',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        }
    },
    mounted() {
        this.doFind();
    }
})
</script>


<style scoped>

</style>
