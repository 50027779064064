import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";
import SpeakerPage from "@/pages/master/SpeakerPage.vue";
import SpeakerEditPage from "@/pages/master/SpeakerEditPage.vue";
import DashboardPage from "@/pages/dashboard/DashboardPage.vue";
import Report from "@/pages/report/Report.vue";
import Events from "@/pages/event/EventPage.vue";
import EventDetail from "@/pages/event/EventDetail.vue";
import EventActive from "@/pages/event/EventActive.vue";
import User from "@/pages/account/User.vue";
import Role from "@/pages/account/Role.vue";
import Sponsor from "@/pages/master/SponsorPage.vue";
import SponsorEditPage from "@/pages/master/SponsorEditPage.vue";
import ExhibitorPage from "@/pages/master/ExhibitorPage.vue";
import ExhibitorEditPage from "@/pages/master/ExhibitorEditPage.vue";
import MemberPage from "@/pages/master/MemberPage.vue";
import EventEditPage from "@/pages/event/EventEditPage.vue";
import MemberEditPage from "@/pages/master/MemberEditPage.vue";
import SponsorAddPage from "@/pages/master/SponsorAddPage.vue";
import EventAddPage from "@/pages/event/EventAddPage.vue";
import ExhibitorAddPage from "@/pages/master/ExhibitorAddPage.vue";
import SpeakerAddPage from "@/pages/master/SpeakerAddPage.vue";
import LoginPage from "@/pages/LoginPage.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: DashboardPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/report',
        name: 'Report',
        component: Report,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/speaker',
        name: 'Speaker',
        component: SpeakerPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/speaker/edit/:id',
        name: 'SpeakerEdit',
        component: SpeakerEditPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/speaker/add',
        name: 'SpeakerAdd',
        component: SpeakerAddPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/member',
        name: 'Member',
        component: MemberPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/member/edit/:id',
        name: 'MemberEdit',
        component: MemberEditPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/event-active',
        name: 'EventActive',
        component: EventActive,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/event',
        name: 'Events',
        component: Events,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/event/edit/:id',
        name: 'EventEdit',
        component: EventEditPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/event/add',
        name: 'EventAdd',
        component: EventAddPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/event-detail',
        name: 'EventDetail',
        component: EventDetail,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/sponsor',
        name: 'Sponsor',
        component: Sponsor,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/sponsor/edit/:id',
        name: 'SponsorEdit',
        component: SponsorEditPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/sponsor/add',
        name: 'SponsorAdd',
        component: SponsorAddPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/exhibitor',
        name: 'Exhibitor',
        component: ExhibitorPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/exhibitor/edit/:id',
        name: 'ExhibitorEdit',
        component: ExhibitorEditPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/exhibitor/add',
        name: 'ExhibitorAdd',
        component: ExhibitorAddPage,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/user',
        name: 'User',
        component: User,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/role',
        name: 'Role',
        component: Role,
        meta: {
            layout: 'main'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: {
            layout: 'login'
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => {
            // localStorage.removeItem('token')
            this.$store.dispatch('onLogout')
            router.push({'name': 'Login'})
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


router.beforeEach((to, from, next) => {
    console.log(to, from, next)

    const isLoggedIn = store.getters.isLoggedIn

    console.log('isLoggedIn = ' + isLoggedIn)

    if (to.name !== 'Login' && !isLoggedIn) {
        next({name: 'Login'})
    } else if (to.name === 'Login' && isLoggedIn) {
        next({name: 'Home'})
    } else {
        next()
    }
})

export default router
