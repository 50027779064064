<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
            <div class="sidebar-brand-text mx-3">IPC Admin</div>
        </a>

        <!-- Divider -->
        <hr class="sidebar-divider my-0" />

        <!-- Nav Item - Dashboard -->
        <li class="nav-item active">
            <router-link to="/dashboard" class="nav-link">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span>
            </router-link>
        </li>

        <li class="nav-item">
            <router-link to="/report" class="nav-link">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Report</span>
            </router-link>
        </li>

<!--        <hr class="sidebar-divider">

        <li class="nav-item">
            <router-link to="/user" class="nav-link">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>User</span>
            </router-link>
        </li>

        <li class="nav-item">
            <router-link to="/role" class="nav-link">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Role</span>
            </router-link>
        </li>-->

        <!-- Divider -->
        <hr class="sidebar-divider">

        <li class="nav-item">
            <router-link to="/member" class="nav-link">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Member</span>
            </router-link>
        </li>

        <li class="nav-item">
            <router-link to="/speaker" class="nav-link">
                <i class="fas fa-fw fa-store"></i>
                <span>Speaker</span>
            </router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider">

        <li class="nav-item">
            <router-link to="/event-active" class="nav-link">
                <i class="fas fa-fw fa-money-check"></i>
                <span>Active Event</span>
            </router-link>
        </li>

        <li class="nav-item">
            <router-link to="/event" class="nav-link">
                <i class="fas fa-fw fa-money-check"></i>
                <span>Event</span>
            </router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider">

        <li class="nav-item">
            <router-link to="/sponsor" class="nav-link">
                <i class="fas fa-fw fa-money-check"></i>
                <span>Sponsor</span>
            </router-link>
        </li>

        <li class="nav-item">
            <router-link to="/exhibitor" class="nav-link">
                <i class="fas fa-fw fa-money-check"></i>
                <span>Exhibitor</span>
            </router-link>
        </li>

    </ul>
</template>


<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Sidebar"
})
</script>


<style scoped>

</style>
