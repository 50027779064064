<template>
    <div class="container-fluid">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Member</h1>
        </div>

        <MemberList></MemberList>
    </div>

</template>

<script>
import {defineComponent} from 'vue'
import MemberList from "@/components/master/MemberList.vue";

export default defineComponent({
    name: "MemberPage",
    components: {
        MemberList
    }
})
</script>


<style scoped>

</style>
