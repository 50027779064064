<template>
    <form name="form-sponsor" id="form-sponsor" @submit="onSubmit">
        <input type="hidden" name="id" v-model="model.id">
        <input type="hidden" name="pic" v-model="model.image">

        <div class="form-row">

            <div class="col-md-6">
                <div class="form-group">
                    <label for="name">Name</label>
                    <InputText class="form-control" v-model="model.name" id="name" :required="true"></InputText>
                </div>

                <div class="form-group">
                    <label for="category">Category</label>
                    <select name="category" id="category" class="form-control" v-model="model.category" required>
                        <option value="0" selected disabled>Select a category</option>
                        <option v-for="(data) in categories" :key="data" :value="data.value">{{ data.label }}
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="website">Website</label>
                    <InputText class="form-control" v-model="model.url" id="website" :required="true"></InputText>
                </div>

                <div class="form-group">
                    <label for="pic">Banner</label>
                    <FileUpload mode="advanced" name="file" :multiple="false" accept="image/*" :url="uploadUrl" @error="onUploadError" @upload="onUploadSuccess"></FileUpload>
                </div>
            </div>

            <div class="col-md-6">


                <div class="form-group">
                    <label for="description">Description</label>
                    <Textarea class="form-control" v-model="model.description" id="description" rows="9" :required="true"></Textarea>
                </div>

                <div class="form-group">
                    <label for="status">Status</label>
                    <select class="form-control" name="status" id="status" v-model="model.status">
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-end mb-sm-1">
            <button type="button" class="btn btn-outline-secondary mr-sm-1" @click="onCancel">Cancel</button>
            <button type="submit" class="btn btn-primary" :disabled="saving">Submit</button>
        </div>

    </form>
</template>


<script>
import {defineComponent} from 'vue'
import apiService from "@/services/ApiService";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import upload from "primevue/icons/upload/index.vue";

export default defineComponent({
    name: "SponsorForm",
    computed: {
        upload() {
            return upload
        }
    },
    components: {
        FileUpload, InputText, Textarea
    },
    props: {
        id: {
            type: String,
            required: false
        },
        isNew: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            model: {
                id: '',
                name: '',
                category: 0,
                image: '',
                url: '',
                status: 1,
                description: '',
                uploadFile: ''
            },
            saving: false,
            categories: [{label: '', value: ''}],
            uploadUrl: ''
        }
    },

    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.saving = true;

            let url = '/sponsor/edit';
            let successMessage = 'Sponsor has been updated';
            if(this.isNew){
                url =  '/sponsor/add';
                successMessage = 'Sponsor has been added';
            }

            apiService.post(url, this.model)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: successMessage,
                        life: 5000
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: error.response.data.Message,
                        life: 5000
                    });
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        onCancel() {
            this.$router.push('/sponsor')
        },
        onUploadSuccess(event){
            let response = JSON.parse(event.xhr.response);
            console.log(event.xhr.response);

            this.model.image = response.fileName;

            this.$toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'File uploaded',
                life: 5000
            });
        },
        onUploadError(event){
            console.error(event);
            console.log(apiService.defaults.baseURL);
            let message = 'Request not found';
            if(event.xhr.status !== 404){
                let response = JSON.parse(event.xhr.response);
                message = response.message;
            }

            this.$toast.add({
                severity: 'error',
                summary: 'Failed to upload file',
                detail: message,
                life: 5000
            });
        },
        getSponsorById(id) {
            apiService.get(`/sponsor/${id}`)
                .then(response => {
                    this.model = response.data;
                    console.log(this.model);
                    this.model.uploadFile = 'https://www.transparentpng.com/thumb/owl/LPmpn3-white-owl-bird-free-photo-pixabay.png';
                }).catch(error => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        },
        getCategories() {
            apiService.get('/sponsor/category')
                .then(response => {
                    this.categories = response.data;
                }).catch(error => {
                console.error(error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        }
    },
    mounted() {
        this.uploadUrl = `${apiService.defaults.baseURL}/file/upload`;
        this.getCategories();
        if(!this.isNew){
            this.getSponsorById(this.id);
        }
    }
})
</script>

<style scoped>

</style>
