<template>
    <div class="container-fluid">

        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Sponsor Edit</h1>
        </div>

        <SponsorForm :id="this.$route.params.id" :is-new="false"></SponsorForm>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import SponsorForm from "@/components/master/SponsorForm.vue";

export default defineComponent({
    name: "SponsorEditPage",
    components: {SponsorForm}
})
</script>


<style scoped>

</style>
