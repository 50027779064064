<template>
    <div class="container-fluid">

        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Event Add</h1>
        </div>

        <EventForm :is-new="true"></EventForm>
    </div>

</template>

<script>
import {defineComponent} from 'vue'
import EventForm from "@/components/event/EventForm.vue";

export default defineComponent({
    name: "EventAddPage",
    components: {EventForm}
})
</script>


<style scoped>

</style>
