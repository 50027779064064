<template>



    <div id="wrapper" v-if="$route.meta.layout === 'main'">
        <Sidebar></Sidebar>
        <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
                <Navbar/>

                <Toast ref="toast"/>

                <router-view/>
            </div>
        </div>
    </div>

    <div class="container" v-if="$route.meta.layout === 'login'">
        <router-view/>
    </div>



</template>

<script>

import Navbar from "@/pages/layout/Navbar.vue";
import Sidebar from "@/pages/layout/Sidebar.vue";

export default {
    name: 'App',
    components: {
        Navbar, Sidebar
    }
}
</script>

<style>

@import './styles/sb-admin-2.css';

#app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    /*text-align: center;*/
    //color: #2c3e50;
    //margin-top: 60px;
}
</style>
