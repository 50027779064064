<template>

    <section class="ftco-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-7 text-center mb-5">
                    <h2 class="heading-section">International Pepper Community 2023</h2>
                </div>
            </div>
            <div class="row justify-content-center">

                <div class="col-md-6 col-lg-4">
                    <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="ui.error">
                        {{ ui.message }}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="login-wrap p-0">
                        <form class="signin-form" @submit="onSubmit()" @submit.prevent="false">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Username" v-model="model.username"  required>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Password"  v-model="model.password" required>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="form-control btn btn-primary submit px-3">Sign In</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import {defineComponent} from 'vue'
import apiService from "@/services/ApiService";

export default defineComponent({
    name: "LoginPage",
    data() {
        return {
            model: {
                username: '',
                password: ''
            },
            ui: {
                error: false,
                message: ''
            }
        }
    },
    methods: {

        onSubmit() {

            const payload = {
                username: this.model.username,
                password: this.model.password
            };

            apiService.post('/login', payload).then(response => {
                console.log(response);
                this.$store.dispatch('onLoggedIn', response.data)
                this.$router.push('/')

            }).catch(error => {
                console.error(error);

                this.ui.error = true;
                if(error.response.status === 404){
                    this.ui.message = 'Request not found';
                }else {
                    this.ui.message = error.response.data.Message;
                }




            });

            return false;
        }
    }
})
</script>

<style scoped>

</style>
