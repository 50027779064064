<template>
    <div class="container-fluid">

        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Member Edit</h1>
        </div>

        <MemberForm :id="this.$route.params.id"></MemberForm>
    </div>

</template>

<script>
import {defineComponent} from 'vue'
import MemberForm from "@/components/master/MemberForm.vue";

export default defineComponent({
    name: "MemberEditPage",
    components: {MemberForm}
})
</script>


<style scoped>

</style>
