<template>
    <div class="container-fluid">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Sponsor</h1>
        </div>

        <SponsorList></SponsorList>
    </div>

</template>

<script>
import {defineComponent} from 'vue'
import SponsorList from "@/components/master/SponsorList.vue";

export default defineComponent({
    name: "SponsorPage",
    components: {
        SponsorList
    }
})
</script>

<style scoped>

</style>
