<template>

    <div class="container-fluid">

        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Speaker Add</h1>
        </div>

        <SpeakerForm :is-new="true"></SpeakerForm>
    </div>

</template>

<script>
import {defineComponent} from 'vue'
import SpeakerForm from "@/components/master/SpeakerForm.vue";

export default defineComponent({
    name: "SpeakerAddPage",
    components: {SpeakerForm}
})
</script>

<style scoped>

</style>
