import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';

import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import './styles/app.css';
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"

import { createApp } from 'vue'
import store from "@/store";
import router from "@/router";
import App from './App.vue'

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice'

import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog';
import dotenv from 'dotenv'

dotenv.config();

console.log('===================== a1');
console.log(process.env.API_BASE_URL);
console.log('===================== a2');

const app = createApp(App);
app.use(router);
app.use(store);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService)
app.component('Toast', Toast)
app.component('ConfirmDialog', ConfirmDialog)

app.mount('#app')
