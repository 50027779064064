<template>
    <div class="container-fluid">

        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Event</h1>
        </div>

        <EventList></EventList>

    </div>
</template>


<script>
import {defineComponent} from 'vue'
import EventList from "@/components/event/EventList.vue";

export default defineComponent({
    name: "EventPage",
    components: {
        EventList
    }
})
</script>


<style scoped>

</style>
