<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "User"
})
</script>

<template>
    <div class="container-fluid">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">User</h1>
        </div>
    </div>
</template>

<style scoped>

</style>
