<template>

    <div class="datatable-toolbar row mb-3">
        <div class="col col-4">
            <input type="text" class="form-control" placeholder="Search data" v-model="dataFilter.searchText"
                   @keyup.enter="doFind();">
        </div>

        <div class="col col-1">
            <button class="btn btn-outline-primary" type="button" @click="doFind();">Search</button>
        </div>

        <div class="col col-2">
            <RouterLink to="/speaker/add" class="btn btn-primary">Add New</RouterLink>
        </div>

        <ConfirmDialog></ConfirmDialog>
    </div>

    <table class="table table-striped">

        <thead>
        <tr>
            <th scope="col">No</th>
            <th scope="col">Name</th>
            <th scope="col">Company Name</th>
            <th scope="col">Current Position</th>
            <th scope="col">PIC</th>
            <th scope="col">Actions</th>
        </tr>
        </thead>

        <tbody>

        <tr v-if="dataList.length === 0">
            <td colspan="5" class="text-center">Empty data list</td>
        </tr>

        <tr v-for="(data, index) in dataList" :key="data">
            <td class="order-number">{{ index + 1 }}</td>
            <td class="text-left">{{ data.name }}</td>
            <td class="text-left">{{ data.companyName }}</td>
            <td class="text-left">{{ data.currentPosition }}</td>
            <td v-if="data.image == null || data.image === ''"></td>
            <td v-if="data.image != null && data.image !== ''">
                <Image :src="getImageUrl(data.image)" alt="Image" width="50" preview />
            </td>
            <td class="actions">
                <button type="button" class="btn btn-outline-secondary" @click="onEdit(data)">Edit</button>
                <button type="button" class="btn btn-outline-secondary" @click="onRemove(data)">Remove</button>
            </td>
        </tr>
        </tbody>

    </table>

</template>

<script>
import {defineComponent} from 'vue'
import ConfirmDialog from "primevue/confirmdialog";
import apiService from "@/services/ApiService";
import Image from "primevue/image";


export default defineComponent({
    name: "SpeakerList",
    components: {
        ConfirmDialog, Image
    },
    data() {
        return {
            dataList: [{
                id: '',
                name: '',
                email: '',
                companyName: '',
                phoneNumber: '',
                currentPosition: '',
                biography: '',
                image: ''
            }],
            showRemoveConfirmDialog: false,
            dataFilter: {
                searchText: ''
            }
        }
    },
    methods: {
        onEdit(data) {
            this.$router.push(`/speaker/edit/${data.id}`);
        },
        onRemove(data) {
            this.$confirm.require({
                message: 'Are you sure you want to remove ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.doRemove(data.id);
                }
            });
        },
        doFind() {
            const params = {
                SearchText: this.dataFilter.searchText
            };
            apiService.get('/speaker', {params: params})
                .then(response => {
                    this.dataList = response.data;
                }).catch(error => {
                console.log(error);

                this.$toast.add({
                    severity: 'error',
                    summary: 'Failed to get speaker',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        },
        doRemove(id) {
            apiService.delete(`/speaker/${id}`)
                .then(() => {
                    this.doFind();

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Speaker has been removed',
                        life: 5000
                    });

                }).catch(error => {

                console.log(error);

                this.$toast.add({
                    severity: 'error',
                    summary: 'Failed to remove speaker',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        },
        getImageUrl(image){
            return `${apiService.defaults.baseURL}/file/${image}`;
        }
    },
    mounted() {
        this.doFind();
    }
})
</script>


<style scoped>

</style>
