<template>

    <div class="datatable-toolbar row mb-3">
        <div class="col col-4">
            <input type="text" class="form-control" placeholder="Search data" v-model="dataFilter.searchText" @keyup.enter="doFind();">
        </div>

        <div class="col col-1">
            <button class="btn btn-outline-primary" type="button" @click="doFind();">Search</button>
        </div>

        <ConfirmDialog></ConfirmDialog>

    </div>


    <div class="row">
        <table class="table table-striped">

            <thead>
            <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>
                <th scope="col">Organization</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
<!--                <th scope="col">Gender</th>-->
                <th scope="col">City</th>
<!--                <th scope="col">Payment Status</th>-->
                <th scope="col">Actions</th>
            </tr>
            </thead>

            <tbody>

            <tr v-if="dataList.length === 0">
                <td colspan="7" class="text-center">Empty data list</td>
            </tr>

            <tr v-for="(data, index) in dataList" :key="data">
                <td class="order-number">{{ index + 1 }}</td>
                <td class="text-left">{{ data.name }}</td>
                <td class="text-left">{{ data.organizationName }}</td>
                <td class="text-left">{{ data.email }}</td>
                <td>{{ data.phoneNumber }}</td>
<!--                <td class="text-left">{{ data.genderLabel }}</td>-->
                <td class="text-left">{{ data.cityName }}</td>
<!--                <td class="text-left"></td>-->
                <td class="actions">
                    <button type="button" class="btn btn-outline-secondary" @click="onEdit(data)">Edit</button>
                    <button type="button" class="btn btn-outline-secondary" @click="onRemove(data)">Remove</button>
                </td>
            </tr>
            </tbody>

        </table>
    </div>


</template>

<script>
import {defineComponent} from 'vue'
import apiService from "@/services/ApiService";
import {useToast} from "primevue/usetoast";

export default defineComponent({
    name: "MemberList",
    data() {
        return {
            dataList: [{id: '', name: '', email: '', phoneNumber: '', gender: '', organizationName: '', website: '', preferredBadgeName: '', address: '', fax: '', cityName: '', genderLabel: ''}],
            showRemoveConfirmDialog: false,
            dataFilter: {
                searchText: ''
            }
        }
    },
    methods: {
        onEdit(data) {
            this.$router.push(`/member/edit/${data.id}`);
        },
        onRemove(data) {
            this.$confirm.require({
                message: 'Are you sure you want to remove ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.doRemove(data.id);
                }
            });
        },
        doFind() {
            const params = {
                SearchText: this.dataFilter.searchText
            };
            apiService.get('/member', {params: params})
                .then(response => {
                    this.dataList = response.data;
                }).catch(error => {
                console.log(error);

                const toast = useToast();
                toast.add({
                    severity:'danger',
                    // summary: 'Info Message',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        },
        doRemove(id) {
            apiService.delete(`/member/${id}`)
                .then(() => {
                    this.doFind();

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Member has been removed',
                        life: 5000
                    });

                }).catch(error => {

                console.log(error);

                this.$toast.add({
                    severity: 'error',
                    summary: 'Failed to remove member',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        }
    },
    mounted() {
        this.doFind();
    }
})
</script>


<style scoped>

</style>
