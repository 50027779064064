<template>
    <form name="form-exhibitor" id="form-exhibitor" @submit="onSubmit">
        <input type="hidden" name="id" v-model="model.id">
        <input type="hidden" name="pic" v-model="model.image">

        <div class="form-row">

            <div class="col-md-6">

                <div class="form-group">
                    <label for="boothNumber">Booth Number</label>
                    <InputText class="form-control" v-model="model.boothNumber" id="boothNumber" :required="true"></InputText>
                </div>

                <div class="form-group">
                    <label for="companyName">Company Name</label>
                    <InputText class="form-control" v-model="model.companyName" id="companyName" :required="true"></InputText>
                </div>

                <div class="form-group">
                    <label for="companyDescription">Company Description</label>
                    <Textarea class="form-control" v-model="model.companyDescription" id="companyDescription" rows="5" :required="true"></Textarea>
                </div>

                <div class="form-group">
                    <label for="productName">Product Name</label>
                    <InputText class="form-control" v-model="model.productName" id="productName" :required="true"></InputText>
                </div>

                <div class="form-group">
                    <label for="productDescription">Product Description</label>
                    <Textarea class="form-control" v-model="model.productDescription" id="productDescription" rows="5" :required="true"></Textarea>
                </div>

                <div class="form-group">
                    <label for="pic">Banner</label>
                    <FileUpload mode="advanced" name="file" :multiple="false" accept="image/*" :url="uploadUrl" @error="onUploadError" @upload="onUploadSuccess"></FileUpload>
                </div>
            </div>

            <div class="col-md-6">

                <div class="form-group">
                    <label for="address">Address</label>
                    <Textarea class="form-control" v-model="model.address" id="address" rows="5"></Textarea>
                </div>

                <div class="form-group">
                    <label for="contactPerson">Contact Person</label>
                    <InputText class="form-control" v-model="model.contactPerson" id="contactPerson"></InputText>
                </div>

                <div class="form-group">
                    <label for="email">Email</label>
                    <InputText class="form-control" v-model="model.email" id="email"></InputText>
                </div>

                <div class="form-group">
                    <label for="website">Website</label>
                    <InputText class="form-control" v-model="model.website" id="website"></InputText>
                </div>

                <div class="form-group">
                    <label for="youtube">Youtube</label>
                    <InputText class="form-control" v-model="model.youtube" id="youtube"></InputText>
                </div>

                <div class="form-group">
                    <label for="status">Status</label>
                    <select class="form-control" name="status" id="status" v-model="model.status">
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </div>

            </div>

        </div>

        <div class="d-flex justify-content-end mb-sm-1">
            <button type="button" class="btn btn-outline-secondary mr-sm-1" @click="onCancel">Cancel</button>
            <button type="submit" class="btn btn-primary" :disabled="saving">Submit</button>
        </div>

    </form>
</template>
<script>

import {defineComponent} from 'vue'
import FileUpload from "primevue/fileupload";
import apiService from "@/services/ApiService";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";

export default defineComponent({
    name: "ExhibitorForm",
    components: {
        FileUpload, InputText, Textarea
    },
    props: {
        id: {
            type: String,
            required: false
        },
        isNew: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            model: {
                id: '',
                boothNumber: '',
                companyName: '',
                companyDescription: '',
                productName: '',
                productDescription: '',
                address: '',
                contactPerson: '',
                website: '',
                youtube: '',
                image: '',
                status: '',
                uploadFile: ''
            },
            saving: false,
            categories: [{label: '', value: ''}],
            uploadUrl: ''
        }
    },
    methods: {
        onSubmit(event) {

            event.preventDefault();

            this.saving = true;

            let url = '/exhibitor/edit';
            let successMessage = 'Exhibitor has been updated';
            let errorMessage = 'Failed to update exhibitor';
            if(this.isNew){
                url = '/exhibitor/add';
                successMessage = 'Exhibitor has been added';
                errorMessage = 'Failed to add exhibitor'
            }
            apiService.post(url, this.model)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: successMessage,
                        life: 5000
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.add({
                        severity: 'error',
                        summary: errorMessage,
                        detail: error.response.data.Message,
                        life: 5000
                    });
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        onUploadSuccess(event){
            let response = JSON.parse(event.xhr.response);
            console.log(event.xhr.response);

            this.model.image = response.fileName;

            this.$toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'File uploaded',
                life: 5000
            });
        },
        onUploadError(event){
            let response = JSON.parse(event.xhr.response);
            console.log();
            this.$toast.add({
                severity: 'error',
                summary: 'Failed to upload file',
                detail: response.Message,
                life: 5000
            });
        },
        getSponsorById(id) {
            apiService.get(`/exhibitor/${id}`)
                .then(response => {
                    this.model = response.data;
                    console.log(this.model);
                }).catch(error => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Failed to get exhibitor',
                    detail: error.response.Message,
                    life: 5000
                });
            });
        },
        onCancel() {
            this.$router.push('/exhibitor')
        },
    },
    mounted() {
        this.uploadUrl = `${apiService.defaults.baseURL}/file/upload`

        if(!this.isNew){
            this.getSponsorById(this.id);
        }
    }
})
</script>

<style scoped>

</style>
