import {createStore} from 'vuex'

export default createStore({

    state: {
        token: localStorage.getItem('_ipc_user_token_') || '',
        user: {
            userId: localStorage.getItem('_ipc_user_id_') || '',
            fullName: localStorage.getItem('_ipc_user_full_name_') || '',
            roleId: localStorage.getItem('_ipc_user_role_code_') || '',
            roleName: localStorage.getItem('_ipc_user_role_name_') || '',
        }
    },
    getters: {
        isLoggedIn: state => state.token !== null && state.token !== '',
        getUser: state => state.user
    },
    mutations: {

        SET_LOGIN(state, data) {
            console.log(data)
            state.token = data.token
            state.user = data.user
        },

        SET_LOGOUT(state) {
            console.log(state)
            state.token = ''
            state.user = {
                userId: '',
                fullName: '',
                roleId: '',
                roleName: ''
            }
        }
    },
    actions: {
        onLoggedIn({commit}, data) {

            console.log('onLoggedIn');
            console.log(data);

            localStorage.setItem('_ipc_user_token_', data.token)
            localStorage.setItem('_ipc_user_id_', data.id)
            localStorage.setItem('_ipc_user_full_name_', data.username)
            localStorage.setItem('_ipc_user_role_code_', data.roleId)
            localStorage.setItem('_ipc_user_role_name_', data.roleName)

            let user = {
                userId: data.id,
                fullName: data.username,
                roleId: data.roleId,
                roleName: data.roleName,
                token: data.token
            }

            commit('SET_LOGIN', {token: data.token, user: user})
        },
        onLogout({commit}) {

            console.log('onLogout');
            localStorage.removeItem('_ipc_user_token_')
            localStorage.removeItem('_ipc_user_id_')
            localStorage.removeItem('_ipc_user_full_name_')
            localStorage.removeItem('_ipc_user_role_code_')
            localStorage.removeItem('_ipc_user_role_name_')
            commit('SET_LOGOUT')
        }
    }
})
