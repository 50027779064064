<template>
    <form name="form-speaker" id="form-speaker" @submit="onSubmit">
        <input type="hidden" name="id" v-model="model.id">

        <div class="form-row">

            <div class="col-md-6">
                <div class="form-group">
                    <label for="name">Event Name</label>
                    <InputText class="form-control" v-model="model.name" id="name"></InputText>
                </div>

                <div class="form-group">
                    <label for="description">Event Description</label>
                    <Textarea class="form-control" v-model="model.description" id="description" rows="9"></Textarea>
                </div>


                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="startDate">Start Date</label>
                            <Calendar v-model="model.startDate" showIcon input-class="form-control" dateFormat="yy-mm-dd" />

                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="endDate">End Date</label>
                            <Calendar v-model="model.endDate" showIcon input-class="form-control" dateFormat="yy-mm-dd" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">

                <div class="form-group">
                    <label for="locationName">Location Name</label>
                    <InputText class="form-control" v-model="model.locationName" id="locationName"></InputText>
                </div>

                <div class="form-group">
                    <label for="locationDescription">Location Description</label>
                    <Textarea class="form-control" v-model="model.locationDescription" id="locationDescription"  rows="9"></Textarea>
                </div>


                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="latitude">Latitude</label>
                            <InputText class="form-control" v-model="model.latitude" id="latitude"></InputText>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="longitude">Longitude</label>
                            <InputText class="form-control" v-model="model.longitude" id="longitude"></InputText>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="d-flex justify-content-end mb-sm-1">
            <button type="button" class="btn btn-outline-secondary mr-sm-1" @click="onCancel">Cancel</button>
            <button type="submit" class="btn btn-primary" :disabled="saving">Submit</button>
        </div>

    </form>
</template>

<script>
import {defineComponent} from 'vue'
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import apiService from "@/services/ApiService";

export default defineComponent({
    name: "EventForm",
    components: {
        InputText, Textarea, Calendar
    },
    props: {
        id: {
            type: String,
            required: false
        },
        isNew: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            model: {
                id: '',
                name: '',
                description: '',
                startDate: '',
                endDate: '',
                locationName: '',
                locationDescription: '',
                latitude: '',
                longitude: '',
                status: ''
            },
            saving: false
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.saving = true;

            let url = '/event/edit';
            let successMessage = 'Event has been updated';
            let errorMessage = 'Failed to update event';
            if(this.isNew){
                url = '/event/add';
                successMessage = 'Event has been added';
                errorMessage = 'Failed to add event';
            }

            apiService.post(url, this.model)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: successMessage,
                        life: 5000
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.add({
                        severity: 'error',
                        summary: errorMessage,
                        detail: error.response.data.Message,
                        life: 5000
                    });
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        onCancel() {
            this.$router.push('/event')
        },
        getEventById(id) {
            apiService.get(`/event/${id}`)
                .then(response => {
                    this.model = response.data;
                }).catch(error => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Failed to get event',
                    detail: error.response.data.Message,
                    life: 5000
                });
            });
        }
    },
    mounted() {

        if(!this.isNew){
            this.getEventById(this.id);
        }

    }
})
</script>

<style scoped>

</style>
