<template>

    <div class="container-fluid">

        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Speaker</h1>
        </div>

        <SpeakerList></SpeakerList>
    </div>

</template>

<script>
import {defineComponent} from 'vue'
import SpeakerList from "@/components/master/SpeakerList.vue";

export default defineComponent({
    name: "SpeakerPage",
    components: {
        SpeakerList
    },
    data() {
        return {
            visible: false,
            showRemoveConfirmDialog: false
        }
    },
    methods: {
        onSave(event) {
            this.$refs.speakerForm.onSubmit(event);
        },
        onShowRemoveConfirmDialog() {
            this.showRemoveConfirmDialog = true;
        }
    }
})
</script>


<style scoped>

</style>
